<template>
    <contentCardIndex >
        <template slot="cardContent">
                <v-row align="center">
                    <v-col cols="12" md="3" lg="3" class="mx-0 px-0">
                        <alternativeTextField2
                            @onChanged="(v) => {trySearch(v);}" 
                            :valueInput="tableProps.search"
                            :rule="[]"
                            :placeholder="'Buscar'"
                            :label="''"
                            :prependInnerIcon="'mdi-magnify'"
                            class="pick-3"
                            data-cy="search-input"
                        ></alternativeTextField2>
                    </v-col>
                    <v-spacer></v-spacer>
                    
                </v-row>
                <v-row class="mt-md-0">
                    <v-col class="mx-0 px-0">
                        <datatable data-cy="index-table" :props="tableProps" @methodHandler="method_handler">
                        
                        </datatable>
                    </v-col>
                </v-row>

                <!-- MODAL DELETE -->
                <modal data-cy="index-modal" :props="modalProps" @modalResponse="method_handler">
                    <!-- Buttons -->
                    <template slot="cardActions">
                        <v-row justify="end" data-cy="index-modal-actions">
                            <v-col cols="12" sm="4">
                                <secondaryButton
                                data-cy="cancel-modal-btn"
                                :props="cancelModal"
                                ></secondaryButton>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <primaryButton
                                data-cy="accept-modal-btn"
                                :props="acceptModal"
                                ></primaryButton>
                            </v-col>
                        </v-row>
                    </template> 
                </modal>

                <!-- MODAL EMAIL -->
                <modal :props="modalMailProps" @modalResponse="method_handler">
                    <template slot="cardBody">
                        <p>
                            Al aceptar será enviado un correo con la 
                            orden de compra adjunta a la cuenta <span class="font-weight-black">{{supplierEmail}}</span> 
                            para <span class="font-weight-black">{{supplierName}}</span> 
                        </p>
                    </template>
                    <!-- Buttons -->
                    <template slot="cardActions">
                        <v-row justify="end" data-cy="index-modal-actions">
                            <v-col cols="12" sm="4">
                                <secondaryButton
                                data-cy="cancel-modal-btn"
                                :props="cancelMailModal"
                                ></secondaryButton>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <primaryButton
                                data-cy="accept-modal-btn"
                                :props="acceptMailModal"
                                ></primaryButton>
                            </v-col>
                        </v-row>
                    </template> 
                </modal>
        </template>

        
    
    </contentCardIndex>

        
</template>
<script>
import Utils from '../../../helpers/Utils'
export default {
    data(){
		return {
            supplierName:"",
            supplierEmail: "",
           
			search: '',
            selectedID: '',
			actions: [
               
				{
					name: 'Detalles',
					icon: {
						color: 'primary',
						icon: 'mdi-information-outline',
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'products.edit',
                    dataCy: "details-link"
				},
                {
					name: 'Editar',
					icon: {
						color: 'primary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'products.edit',
                    dataCy: "edit-link"
				},
                {
					name: 'Enviar correo',
					icon: {
						color: 'primary',
						icon: 'mdi-email-arrow-right-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'openModalMail',
                    permissions: 'products.show',
                    dataCy: "send-link",
                    loading: false
				},
                 {
					name: 'Registrar pago',
					icon: {
						color: 'primary',
						icon: 'mdi-credit-card-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'products.edit',
                    dataCy: "payment-link"
				},
               
                {
					name: 'Imprimir',
					icon: {
						color: 'primary',
						icon: 'mdi-printer-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'getPdf',
                    permissions: 'products.delete',
                    dataCy: "pdf-link",
                    loading: false
				},
				{
					name: 'Borrar',
					icon: {
						color: 'primary',
						icon: 'mdi-delete-outline',
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'products.delete',
                    dataCy: "delete-link"
				},

               
			],
			tableProps: {
				headers: [
                    {
						text: 'Folio',
						align: 'left break-words',
						value: 'folio',
                        width: "9%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Requisición',
						align: 'center break-words',
						value: 'requisition_folio',
                        width: "9%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Proveedor',
						align: 'center break-words',
						value: 'supplier.name',
                        width: '25%',
						class: 'table-b-border black--text'
					},
                     {
						text: 'Fecha',
						align: 'center break-words',
						value: 'date',
                        width: '9%',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Subtotal',
						align: 'center break-words',
						value: 'subtotal',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Iva',
						align: 'center break-words',
						value: 'iva',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Total',
						align: 'center break-words',
						value: 'total',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Pagado',
						align: 'center break-words',
						value: 'paid',
						class: 'table-b-border black--text'
					},
                   
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '14%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
			
			
         

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                cy:"cancel-modal-btn",
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                cy:"accept-modal-btn",
                click: ()=>{this.delete()}
            },




            modalMailProps: {
				visible: false,
				width: '500',
				title: 'Enviar correo',
				text: '',
				loading: false,
				bottomLineColor: '',
				
			},
			
			cancelMailModal: {
                text: "Cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                cy:"cancel-modal-btn",
                click: ()=>{this.modalMailProps.visible = false}
            },
			acceptMailModal: {
                text: "Aceptar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                cy:"accept-modal-btn",
                click: ()=>{this.sendMail()}
            },
		}
	},

	mounted(){
		this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            // if(window.innerWidth < 960){
            //     this.createBtnData.block = true;
                
            // }
            // else{
            //     this.createBtnData.block = false;
                
            // }
        },
      
        trySearch(val){
            this.tableProps.search = val;
        },
		index() {
			this.tableProps.loading = true;
           
			// call api
			this.$api.purchaseOrder.list()
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.reverse().map((x,i) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
                       
						
                        x.actions[0].action = { name: 'PurchaseOrdersDetails', params: { id: x.id } }
                        x.actions[1].action = { name: 'PurchaseOrdersEdit', params: { id: x.id } }
                        x.actions[3].action = { name: 'PurchaseOrdersPaymentCreate', params: { purchaseOrderId: x.id } }
						

						x['parameters'] = { id: x.id, name: x.folio, index: i, supplier:x.supplier.name, email:x.supplier.email};
                        const total = parseFloat(x.subtotal) + parseFloat(x.iva);
                        if(x.paid >= total)
                            x.actions[3].disabled = true;
                       
                        x.subtotal = Utils.currencyFormat(x?.subtotal??"", "");
                        x.iva = Utils.currencyFormat(x?.iva??"", "");
                        x.total = Utils.currencyFormat(total, "");
                        x.paid = Utils.currencyFormat(x.paid, "");

                        x.date = Utils.globalDateFormat(x.date);

                       
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},



		deleteItemModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.purchaseOrder.delete(this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},


        getPdf(parameters)
		{
            this.selectedID = parameters.id;
            this.tableProps.items[parameters.index].actions[4].loading = true
			//console.log(this.selectedID);
			this.$api.purchaseOrder.pdf(this.selectedID)
                // eslint-disable-next-line
                .then((response) => {
                    console.log(response);
                    let file = new Blob([response.data], {type: 'application/pdf'});
                    let fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    // eslint-disable-next-line
                }).catch(error => {
                    if(error.response.status === 422){
                        this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al obtener archivo'}`);
                    }else{
                        this.$store.dispatch('snackbarError', `Error. Intenta de nuevo`);
                    }

                    
                })
                .finally(() => {
                    this.tableProps.items[parameters.index].actions[4].loading = false;
                })
            ;
		},


        openModalMail(parameters)
        {
            this.selectedID = parameters.id;
            this.supplierName = parameters.supplier;
            this.supplierEmail = parameters.email;

            this.modalMailProps.visible = true;
        },

        sendMail()
        {
            // this.selectedID = parameters.id;
            // this.tableProps.items[parameters.index].actions[2].loading = true
            this.acceptMailModal.loading = true;
			//console.log(this.selectedID);
			this.$api.purchaseOrder.sendMail(this.selectedID)
                // eslint-disable-next-line
                .then((response) => {
                    this.$store.dispatch('snackbarSuccess', `El correo ha sido enviado correctamente a ${this.supplierName}`);
                    // eslint-disable-next-line
                }).catch(error => {
                    if(error.response.status === 422){
                        this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al enviar'}`);
                    }else{
                        this.$store.dispatch('snackbarError', `Error. Intenta de nuevo`);
                    }

                    
                })
                .finally(() => {
                    this.acceptMailModal.loading = false;
                    this.modalMailProps.visible = false;
                    // this.tableProps.items[parameters.index].actions[2].loading = false;
                })
            ;
        }

    }
}
</script>